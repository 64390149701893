<template>
    
  <v-card>
    <v-card-title>Crop Group</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-card-title class="pl-0 pr-0 pt-0">
        <v-btn
          color="secondary"
          @click="(dialog = true)"
        >
        <v-icon left>
          mdi-plus
        </v-icon>
        New Crop Group
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="cropPriceHeaders"
        :items="cropsPriceTable"
        :items-per-page="5"
        :search="search"
        class="elevation-1"
      ></v-data-table>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="50%"
      persistent
    >

      <v-card>
        <v-card-title class="text-h5 secondary white--text justify-center">
          New Crop Price
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="cropLists"
              label="Crop Name"
              required
              outlined
              hide-details="auto"
              dense
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="stateLists"
              label="State"
              required
              outlined
              hide-details="auto"
              dense
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="districtLists"
              label="District"
              required
              outlined
              hide-details="auto"
              dense
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Minimum Price"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Maximum Price"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Average Price"
            ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="(dialog = false)"
            outlined
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-card>

</template>

<script>
// -- import something here --

export default {

  data: () => ({
    search: '',
    dialog: false,
    cropLists: ['Asparagus'],
    stateLists: ['Perak'],
    districtLists: ['Kinta'],
    cropPriceHeaders: [
      {
        text: 'Crop',
        align: 'start',
        sortable: false,
        value: 'crop',
      },
      { text: 'District', value: 'district' },
      { text: 'State', value: 'state' },
      { text: 'Date', value: 'date' },
      { text: 'Minimum Price', value: 'price_min' },
      { text: 'Maximum Price', value: 'price_max' },
      { text: 'Average Price', value: 'price_avg' },
      { text: 'Action', value: 'action' },
    ],
    cropsPriceTable: [
      {
        crop: 'Asparagus',
        district: 'Kinta',
        state: 'Perak',
        date: '12/14/2022',
        price_min: '2.50',
        price_max: '3.50',
        price_avg: '3.00',
        action: '',
      }
      
    ],
  }),

  mounted() {
    
  },

  methods: {
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


